<template>
<div class="card">
  <div class="select-filter">
    <div class="card-header">
      <h2 class="mb-0">
        <b-button v-b-toggle="`selectFilter-${item.kind}`" class="btn btn-link lesiurtabFilter-header" variant="link">
          {{item.title}}
        </b-button>
      </h2>
    </div>

    <b-collapse :id="`selectFilter-${item.kind}`" v-model="isVisible">
      <b-card>
        <b-form-select v-model="chainSelected" :options="item.subItems" @change="selectedHotelChain"></b-form-select>
      </b-card>
    </b-collapse>
  </div>
</div>
</template>

<script>
import { BButton, BCollapse, BCard, BFormSelect, VBToggle } from 'bootstrap-vue';

export default {
  name: 'SidebarCollapseItem',
  components: {
    BFormSelect,
    BButton,
    BCollapse,
    BCard,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isVisible: true,
      chainSelected: '',
    };
  },
  mounted() {
    this.chainSelected = this.item.defaultSelect;
  },
  methods: {
    selectedHotelChain() {
      this.$emit('change', { kind: this.item.kind, value: this.chainSelected });
    },
  },
};
</script>
